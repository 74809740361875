import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

function SelectInput(props) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
            <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} onChange={(value) => { props.onChange && props.onChange(value) }}>
                {props.option.map((item, index) => (
                    <Select.Option key={index} value={item[props.keyword]}>{item[props.text]}</Select.Option>
                ))}
            </Select>
        </div>
    )
}

const VideoContext = createContext();

function VideoNew() {
    const { actVideo, dispatchVideo } = useContext(VideoContext)
    const { state } = useContext(StateContext)
    return (
        <div>
            <Input style={{ marginBottom: 5 }} addonBefore="视频名称" value={actVideo.video_name} defaultValue={actVideo.name}
                onChange={(e) => { dispatchVideo({ type: 'update', video: { ...actVideo, name: e.target.value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore='所属区域' defaultValue={actVideo.district} option={state.district} keyword={"id"} text={'name'}
                onChange={value => { console.log(value); dispatchVideo({ type: 'update', video: { ...actVideo, district: value } }) }} />
            <Input style={{ marginBottom: 5 }} addonBefore="视频地址" value={actVideo.liveurl}
                onChange={(e) => { dispatchVideo({ type: 'update', video: { ...actVideo, liveurl: e.target.value } }) }} />
        </div>
    )
}

function VideoManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actVideo, dispatchVideo] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.video
        } else if (action.type === 'clear') {
            return {
                district: '',
                name: "",
                liveurl: '',
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    useEffect(() => {
        http.get("/v1/video", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'video', video: data.data })
            }
        })
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data })
            }
        })
    }, [])
    const columns = [
        {
            title: '视频名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: '视频地址',
            dataIndex: 'liveurl',
            key: 'liveurl',
            align: 'center',
        },
        {
            title: '所属区域',
            dataIndex: 'district',
            key: 'district',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        state.district.filter(x => { return x.id === record.district }).length > 0 ? state.district.filter(x => { return x.id === record.district })[0].name : '无'
                    }
                </div>
            )
        },
        {
            title: '操作',
            width: 200,
            dataIndex: 'operation',
            key: 'operation',
            align: 'right',
            width: '180px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixModel(true)
                        dispatchVideo({ type: 'update', video: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={()=>{
                        Modal.confirm({
                            centered: true,
                            title: '确定要删除视频吗？',
                            icon: <ExclamationCircleOutlined />,
                            content: '删除之后将不可恢复！！',
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/video/" + record.id, {data:record}).then((data) => {
                                    http.get("/v1/video", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'video', video: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div style={{padding:10}}>
            <div style={{ float: 'right', marginBottom: 10 }}>
                <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                onClick={() => {
                    setShowNewModel(true)
                    dispatchVideo({ type: 'clear', project_id: state.main.project_id })
                }}>新建视频</Button>
            </div>
            <Table bordered pagination={{hideOnSinglePage:true}}  rowKey={record => record.id}  columns={state.main.id==1?columns:columns.filter(x=>{return x.dataIndex!='project_id'})} size='small' dataSource={state.video} />
            <Modal maskClosable={false} title="新增视频" visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    setShowNewModel(false)
                    dispatchVideo({ type: 'clear', project_id: state.main.project_id })
                    http.post("/v1/video", actVideo).then((data) => {
                        http.get("/v1/video", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'video', video: data.data })
                            }
                        })
                    })

                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchVideo({ type: 'clear', project_id: state.main.project_id })
                }}>
                <VideoContext.Provider value={{ actVideo, dispatchVideo }}>
                    <VideoNew />
                </VideoContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改视频" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    setShowFixModel(false)
                    dispatchVideo({ type: 'clear', project_id: state.main.project_id })
                    http.put("/v1/video/" + actVideo.id, actVideo).then((data) => {
                        http.get("/v1/video", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'video', video: data.data })
                            }
                        })
                    })
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchVideo({ type: 'clear', project_id: state.main.project_id })
                }}>
                <VideoContext.Provider value={{ actVideo, dispatchVideo }}>
                    <VideoNew />
                </VideoContext.Provider>
            </Modal>
        </div>
    )
}

export default VideoManage