import Sider from './sider'
import Header from './header'
import Content from './content'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN';
import { Mode } from '../../App'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
function Mnanger(props) {
    if (Mode === 'soc') {
        return (
            <ConfigProvider locale={zhCN}>
                <div style={{ display: 'flex', height: '100vh' ,backgroundColor:'#000C17'}}>
                    <Sider />
                    <div style={{ flex:1,height: '100vh', display: 'flex', flexDirection: 'column',backgroundColor:"#E4E5E6" }}>
                        <Header />
                        <Content >{props.children}</Content>
                    </div>
                </div>
            </ConfigProvider>
        )
    } else {
        return (
            <ConfigProvider locale={zhCN}>
                <div style={{ display: 'flex', height: '100vh',flex: 1,flexDirection: 'column'}}>
                    <Header local />
                    <Content >{props.children}</Content>
                </div>
            </ConfigProvider>
        )
    }
}
export default Mnanger;