import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input,  Select, Modal, InputNumber , Form,  Checkbox, DatePicker, Badge, Space,} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { SearchOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { Mode } from '../../../../../App';
import moment from 'moment';
import Draggable from 'react-draggable';
import './index.less'
import sha1 from '../../../../../utils/sha1';
var changeDev = false;
const AreaContext = createContext();
const DeviceContext = createContext();

function  getSimByIccid(iccid,simToken) {
    let r ={};
    fetch( `http://hebeijiuchen.com/sim/card/all/query.do?queryString=${iccid}`, { //物联网卡查询 接口请求地址
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `${simToken}`
        })
    })
    .then((resp) => {
        resp.json().then(res=>{
            if(res.list.length==0){//河北卡查询不到
                let method = 'fc.function.card.info';
                let appKey = 'RL7i4T0Q9024bI7Z';
                let t = Math.floor(new Date().getTime()/1000)
                let sign = callSign(appKey,method,iccid,t);
                fetch(`https://api.quectel.com/openapi/router`,{
                    method:'post',
                    body:`appKey=${appKey}&iccid=${iccid}&method=${method}&sign=${sign}&t=${t}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }).then(resp=>{
                    resp.json().then(res=>{
                        r.iccid_expire =res.expiryDate!==''?res.expiryDate:'';
                        r.sim = res.msisdn;
                    })
                })
                
            }else{
                r.iccid_expire =res.list[0].expireTime!==''?res.list[0].expireTime:'';
                r.sim =''+ res.list[0].mobile;
            }
        })
    })
    return r
}

function callSign (appKey,method,s,t){
    let Secret = 'RA9026m2';
    let concat =`${Secret}appKey${appKey}`;
    if(s.length===13){
        let msisdn = s;
        concat +=`method${method}msisdn${msisdn}t${t}${Secret}`;
    }else{
        let iccid = s;
        concat +=`iccid${iccid}method${method}t${t}${Secret}`
    }
    let r = sha1.sha1(concat);
    return r
}

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  };
function DeviceNew() {
    const { actDevice, form, onfinish } = useContext(DeviceContext)
    const { state, dispatch } = useContext(StateContext)
    const [lockMName, setLockMName] = useState(false)
    const [master, setMaster] = useState(0)
    const [nodeNum, setNodeNum] = useState(1)
    const [nodeStatus1, setNodeStatus1] = useState(false)
    const [nodeStatus2, setNodeStatus2] = useState(false)
    const formRef = React.createRef();
    const [simToken,setSimToken] = useState('')
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    useEffect(() => {
        http.get("/v1/devicetype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'devicetype', devicetype: data.data });
            }
        })
        form.setFieldsValue({ ...actDevice, num: 1, humi: true, status: false, expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'),
            iccid_expire:moment(actDevice.iccid_expire, 'YYYY-MM-DD'),
            temp_alarm_high:50,temp_alarm_low:-50,humi_alarm_high:100,humi_alarm_low:0,power_alarm:true
        })
        fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
            method: 'get',
        })
        .then((resp) => {
            resp.json().then(res=>{
                setSimToken(res.token)
            })
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    return (
        <Form
            form={form}
            {...formItemLayout}
            ref={formRef}
            onFinish={onfinish}
        >
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    label="主机类型"
                    labelCol={{ span: 8 }}
                    name="type"
                    rules={[{ required: true, message: '选择主机类型' }]}>
                    <Select style={{ width: 150 }}
                        placeholder="主机类型"
                        onChange={value => {
                            switch (value) {
                                case 201: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:true
                                }); return;
                                case 202: formRef.current.setFieldsValue({
                                    location: 1, humi: false,power_alarm:false
                                }); return;
                                case 203: formRef.current.setFieldsValue({
                                    location: 0, humi: false,power_alarm:false
                                }); return;
                                case 204: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:false
                                }); return;
                                case 205: formRef.current.setFieldsValue({
                                    location: 2, humi: false,power_alarm:false
                                }); return;
                                case 207: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:false
                                }); return;
                                case 208: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:true
                                }); return;
                                case 209: formRef.current.setFieldsValue({
                                    location: 1, humi: false,power_alarm:false
                                }); return;
                            }
                        }}
                    >
                        {
                            state.devicetype.map(item => (
                                // item.code!==204&&
                                <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="主机编号"
                    name="sn"
                    onChange={e => {
                        const s = state.device.filter(x => { return x.sn === Number(e.target.value)&&x.node!==0 })
                        if (s.length > 0) {
                            setMaster(s.length);
                            setLockMName(true);
                            formRef.current.setFieldsValue({
                                m_name: s[0].m_name,
                                sim: s[0].sim,
                                name:"测点"+(s.length+1),
                                node:s.length+1
                            })
                        } else {
                            setLockMName(false);
                        }
                    }}
                    rules={[{ required: true, message: '输入主机编号' }]}>
                    <Input />
                </Form.Item>
            </div>

            {
                Mode === "local" ?
                    <div style={{ display: 'flex' }}>
                        <Form.Item style={{ width: 400 }}
                            labelCol={{ span: 6 }}
                            label="主机名称"
                            name="m_name"
                            rules={[{ required: true, message: '输入主机名称' }]}>
                            <Input disabled={lockMName} />
                        </Form.Item>
                        <Form.Item style={{ width: 200 }}
                            labelCol={{ span: 12 }}
                            label="平台设备"
                            name="status"
                            valuePropName="checked"
                        >
                            <Checkbox>选择</Checkbox>
                        </Form.Item>
                    </div> :
                    <Form.Item
                        label="主机名称"
                        name="m_name"
                        rules={[{ required: true, message: '输入主机名称' }]}>
                        <Input disabled={lockMName} />
                    </Form.Item>
            }
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 340 }}
                    labelCol={{ span: 7 }}
                    label="测点名称"
                    name="name"
                    rules={[{ required: true, message: '输入测点名称' }]}>
                    <Input disabled={nodeNum > 1} />
                </Form.Item>
                <Form.Item style={{ width: 120, }}
                    labelCol={{ span: 10 }}
                    label="断电"
                    name="power_alarm"
                    valuePropName="checked"
                >
                    <Checkbox 
                    // onChange={e=>{
                    //     if(e.target.checked){
                    //         form.setFieldsValue({node:0,num:1});
                    //         setNodeNum(1);
                    //         setNodeStatus1(true);
                    //     }else{
                    //         setNodeStatus1(false);
                    //         !nodeStatus2&&form.setFieldsValue({node:master+1,name:'测点'+(master+1)});
                    //     }
                    // }}
                    >
                        开启
                    </Checkbox>
                </Form.Item>
                <Form.Item style={{ width: 140, }}
                    labelCol={{ span: 12 }}
                    label="开关门"
                    name="door_alarm"
                    valuePropName="checked"
                >
                    <Checkbox 
                    // onChange={e=>{
                    //     if(e.target.checked){
                    //         form.setFieldsValue({node:0,num:1});
                    //         setNodeNum(1);
                    //         setNodeStatus2(true);
                    //     }else{
                    //         setNodeStatus2(false);
                    //         !nodeStatus1&&form.setFieldsValue({node:master+1,name:'测点'+(master+1)});
                    //     }
                    // }}
                    >
                        开启
                    </Checkbox>
                </Form.Item>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                <Form.Item
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    label="测点ID"
                    name="node"
                    rules={[{ required: true, message: '输入测点ID' }]}>
                    <Input disabled={nodeNum > 1 || nodeStatus1 || nodeStatus2} onChange={e=>
                        e.target.value!==''&&e.target.value*1===0&&form.setFieldsValue({power_alarm:true})
                    }/>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 11 }}
                    wrapperCol={{ span: 13 }}
                    label="测点数量"
                    name="num"
                    rules={[{ required: true, message: '输入测点数量' }]}>
                    <InputNumber min={1} max={99} disabled={nodeStatus1 || nodeStatus2} onChange={value => {
                        setNodeNum(value)
                    }} />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 20 }}
                    wrapperCol={{ span: 13 }}
                    label="已添加测点"
                    name="s">
                    {
                       master
                    }
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="定位方式"
                    name="location"
                    rules={[{ required: true, message: '选择定位方式' }]}>
                    <Select
                        placeholder="定位方式"
                    >
                        {
                            [{ id: 0, name: 'GPS定位' }, { id: 1, name: 'LBS定位' }, { id: 2, name: '无定位' }].map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: 260,marginLeft:state.main.type<103?0:60 }}
                    labelCol={{ span: 8 }}
                    label="SIM卡号"
                    name="sim"
                    rules={[{ required: true, message: '输入SIM卡号' }]}>
                    <Input disabled={lockMName} />
                </Form.Item>
                {state.main.type<102&&<Button onClick={()=>{
                    let iccid = form.getFieldValue('iccid');
                    if (iccid&&iccid.length==20){
                        let r = getSimByIccid(iccid,simToken);
                        setTimeout(()=>{
                            if(r.iccid_expire&&r.iccid_expire!==''){
                                if(r.iccid_expire==='***'){
                                    let t = form.getFieldValue('expire');
                                    console.log(t);
                                }else{
                                    form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                                }
                            }
                            if(r.iccid_expire&&r.iccid_expire ===''){
                                form.setFieldsValue({iccid_expire:moment('2000-01-01', 'YYYY-MM-DD') })
                            }
                            r.sim&&r.sim!==''&&form.setFieldsValue({sim: r.sim })
                        },500)
                    }else{
                        fetch( `http://www.hebeijiuchen.com/sim/card/queryCard.do?mobile=${form.getFieldValue('sim')}`, { //物联网卡查询 接口请求地址
                            method: 'get',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `${simToken}`
                            })
                        })
                        .then((resp) => {
                            resp.json().then(res=>{
                                res.expireTime!==''&&form.setFieldsValue({iccid_expire: moment(res.expireTime, 'YYYY-MM-DD') })
                                res.iccid!==''&& form.setFieldsValue({iccid:res.iccid})
                            }).catch((error) => {
                                let method = 'fc.function.card.info';
                                let appKey = 'RL7i4T0Q9024bI7Z';
                                let msisdn = form.getFieldValue('sim')
                                let t = Math.floor(new Date().getTime()/1000)
                                let sign = callSign(appKey,method,msisdn,t);
                                fetch(`https://api.quectel.com/openapi/router`,{
                                    method:'post',
                                    body:`appKey=${appKey}&method=${method}&msisdn=${msisdn}&sign=${sign}&t=${t}`,
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    }
                                }).then(resp=>{
                                    resp.json().then(res=>{
                                        if(res.resultCode===0){
                                            let date = new Date(form.getFieldValue('expire'));
                                            let newDate = moment(date,'YYYY-MM-DD').endOf('month');
                                            if(res.active==='未激活'){
                                                form.setFieldsValue({iccid_expire: newDate})
                                            }else{
                                                if(res.expiryDate!==''){
                                                    let now = new Date(res.expiryDate).getTime()-newDate.format('x');
                                                    if(now>0){
                                                        form.setFieldsValue({iccid_expire: newDate})
                                                    }else{
                                                        form.setFieldsValue({iccid_expire:moment(res.expiryDate, 'YYYY-MM-DD') })
                                                    }
                                                }
                                            }
                                            res.iccid!==''&& form.setFieldsValue({iccid:res.iccid})
                                        }else{
                                            form.setFieldsValue({iccid_expire:moment('2000-01-01', 'YYYY-MM-DD') })
                                        }
                                    })
                                })
                            });
                        })
                    }
                }}>读取</Button>}
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{width:260}}>
                    <Form.Item 
                        labelCol={{ span: 6 }}
                        label="SIMid"
                        name="iccid"
                    >
                        <Input controls={false} disabled={lockMName || changeDev}  style={{width:185}}/>
                    </Form.Item>
                </div>
                
                <Button onClick={()=>{
                    http.post("/v1/getdevice", { error:false,type:13,validity:false, device: form.getFieldValue('sn'), node: form.getFieldValue('node') })
                    .then(data1 => {
                        http.get("/v1/device", {}).then(data => {
                            if (data.code === 0) {
                                let tempEles = data.data.filter(x=>x.id===actDevice.id);
                                if(tempEles.length>0){
                                    let r = getSimByIccid(tempEles[0].iccid,simToken);
                                    setTimeout(()=>{
                                        if(r.iccid_expire&&r.iccid_expire!==''){
                                            let date = new Date(form.getFieldValue('expire'));
                                            let newDate = moment(date,'YYYY-MM-DD').endOf('month');
                                            if(r.iccid_expire==='***'){
                                                form.setFieldsValue({iccid_expire: newDate})
                                            }else{
                                                let now = new Date(r.iccid_expire).getTime()-newDate.format('x');
                                                if(now>0){
                                                    form.setFieldsValue({iccid_expire: newDate})
                                                }else{
                                                    form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                                                }
                                            }
                                        }
                                        if(r.iccid_expire&&r.iccid_expire ===''){
                                            form.setFieldsValue({iccid_expire:moment('2000-01-01', 'YYYY-MM-DD') })
                                        }
                                        r.sim&&r.sim!==''&&form.setFieldsValue({sim: r.sim })
                                    },500)
                                    form.setFieldsValue({iccid: tempEles[0].iccid })
                                }
                                
                            }
                        })
                    })
                }}>读取</Button>
                {
                    state.main.type<103&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="卡到期时间"
                            name="iccid_expire"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130}}/>
                        </Form.Item>
                    </div>
                    
                }
            </div>
            <div style={{ display: 'none' ,marginLeft:10}}>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14}}
                    label="温度上限"
                    name="temp_alarm_high"
                    rules={[{ required: true, message: '输入温度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="温度下限"
                    name="temp_alarm_low"
                    rules={[{ required: true, message: '输入温度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="湿度上限"
                    name="humi_alarm_high"
                    rules={[{ required: true, message: '输入湿度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="湿度下限"
                    name="humi_alarm_low"
                    rules={[{ required: true, message: '输入湿度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="测点隐藏"
                    name="hide"
                    valuePropName="checked"
                >
                    <Checkbox style={{ width: 80 }}>隐藏</Checkbox>
                </Form.Item>
                {
                    state.main.type< 103 &&
                    <Form.Item style={{ width: 300 }}
                        labelCol={{ span: 8 }}
                        label="安装时间"
                        name="time"
                        rules={[{ required: true, message: '选择安装时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"}  disabledDate={current=>current&&current > moment().endOf('day')}/>
                    </Form.Item>
                }
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="湿度数据"
                    name="humi"
                    valuePropName="checked"
                >
                    <Checkbox >开启</Checkbox>
                </Form.Item>

                {
                    state.main.type < 103 &&Mode==='soc'&&
                    <Form.Item style={{ width: 300 }}
                        labelCol={{ span: 8 }}
                        label="到期时间"
                        name="expire"
                        rules={[{ required: true, message: '选择到期时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type >100}/>
                    </Form.Item>
                }
            </div>

        </Form>
    )
}

function DeviceFix() {
    const { actDevice,  form, onfinish } = useContext(DeviceContext)
    const { state } = useContext(StateContext)
    const formRef = React.createRef();
    const [simToken,setSimToken] = useState('')
    const [simStatus,setSimStatus] = useState('')
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    useEffect(() => {
        form.setFieldsValue({
            ...actDevice,
            humi: actDevice.humi === 0 ? false : true,
            door_alarm: actDevice.door_alarm === 0 ? false : true,
            power_alarm: actDevice.power_alarm === 0 ? false : true,
            hide: actDevice.hide === 0 ? false : true,
            expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'), 
            iccid_expire:moment(actDevice.iccid_expire, 'YYYY-MM-DD')
        })
        fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
            method: 'get',
        })
        .then((resp) => {
            resp.json().then(res=>{
                setSimToken(res.token)
            })
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    return (
        <Form
            form={form}
            {...formItemLayout}
            ref={formRef}
            onFinish={onfinish}>

            <div style={{ display: 'flex' }}>
                <Form.Item label="主机类型"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="type"
                    rules={[{ required: true, message: '选择主机类型' }]}>
                    <Select
                        placeholder="主机类型"
                    >
                        {
                            state.devicetype.map(item => (
                                <Select.Option key={item.id} value={item.code}>{item.value}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="主机编号"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="sn"
                    rules={[{ required: true, message: '输入主机编号' }]}>
                    <Input disabled={changeDev} />
                </Form.Item>
            </div>
            <Form.Item label="主机名称"
                name="m_name"
                rules={[{ required: true, message: '输入主机名称' }]}>
                <Input />
            </Form.Item>
            <div style={{ display: 'flex' }}>
                <Form.Item label="测点名称"
                    labelCol={{ span: 6 }}
                    style={{ width: 400 }}
                    name="name"
                    rules={[{ required: true, message: '输入测点名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="测点ID"
                    labelCol={{ span: 10 }}
                    style={{ width: 200 }}
                    name="node"
                    rules={[{ required: true, message: '输入测点ID' }]}>
                    <Input disabled={changeDev}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="断电"
                    labelCol={{ span: 8 }}
                    style={{ width: 200 }}
                    name="power_alarm"
                    valuePropName="checked"
                >
                    <Checkbox disabled={actDevice.node!==0}>
                        开启
                    </Checkbox>
                </Form.Item>
                <Form.Item label="开关门"
                    labelCol={{ span: 12 }}
                    style={{ width: 200 }}
                    name="door_alarm"
                    valuePropName="checked"
                >
                    <Checkbox disabled={actDevice.node!==0}>
                        开启
                    </Checkbox>
                </Form.Item>
                <span style={{color:'red'}}>{simStatus}</span>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="定位方式"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="location"
                    rules={[{ required: true, message: '选择定位方式' }]}>
                    <Select
                        placeholder="定位方式"
                    >
                        {
                            [{ id: 0, name: 'GPS定位' }, { id: 1, name: 'LBS定位' }, { id: 2, name: '无定位' }].map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="SIM卡号"
                    labelCol={{ span: 8 }}
                    style={{ width: 260,marginLeft:state.main.type===100?0:60}}
                    name="sim"
                    rules={[{ required: true, message: '输入SIM卡号' }]}>
                    <Input style={{width:140}}/>
                </Form.Item>
                {Mode==='soc'&& state.main.type===100&&
                <Button onClick={()=>{
                    let iccid = form.getFieldValue('iccid');
                    let date = new Date(form.getFieldValue('iccid_expire')).toLocaleDateString();
                    // if (iccid&&iccid.length==20){
                    //     let r = getSimByIccid(iccid,simToken);
                    //     setTimeout(()=>{
                    //         if(r.iccid_expire&&r.iccid_expire!==''){
                    //             if (date==='2000/1/1'){
                    //                 let now = new Date(r.iccid_expire).getTime()-new Date().getTime();
                    //                 if(now>365*24*60*60*1000*2){
                    //                     form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(2,'years')});
                    //                 }
                    //                 else if(now>365*24*60*60*1000){
                    //                     form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(1,'years')});
                    //                 }else{
                    //                     form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                    //                 } 
                    //             }else{
                    //                 form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                    //             }
                                 
                    //         }
                    //         r.sim&&r.sim!==''&&form.setFieldsValue({sim: r.sim })
                    //     },500)
                        
                    // }else{
                        fetch( `http://www.hebeijiuchen.com/sim/card/queryCard.do?mobile=${form.getFieldValue('sim')}`, { //物联网卡查询 接口请求地址
                            method: 'get',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `${simToken}`
                            })
                        })
                        .then(resp => {
                            resp.json().then(res=>{
                                if(res.expireTime!==''){
                                    setSimStatus(res.expireTime);
                                    if(date==='2000/1/1'){
                                        form.setFieldsValue({iccid_expire:moment(res.expireTime, 'YYYY-MM-DD') })
                                    }
                                }else{
                                    setSimStatus('未激活');
                                    form.setFieldsValue({iccid_expire:moment(new Date(), 'YYYY-MM-DD').add(1,"years").endOf('month') })
                                }
                                if(res.iccid!==''){
                                    if(iccid&&iccid.length==20){
                                        iccid!==res.iccid&&setSimStatus('iccid不相同，核实卡信息')
                                    }else{
                                        form.setFieldsValue({iccid:res.iccid})
                                    }
                                }
                            }).catch((error) => {
                                let method = 'fc.function.card.info';
                                let appKey = 'RL7i4T0Q9024bI7Z';
                                let msisdn = form.getFieldValue('sim')
                                let t = Math.floor(new Date().getTime()/1000)
                                let sign = callSign(appKey,method,msisdn,t);
                                fetch(`https://api.quectel.com/openapi/router`,{
                                    method:'post',
                                    body:`appKey=${appKey}&method=${method}&msisdn=${msisdn}&sign=${sign}&t=${t}`,
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded',
                                    }
                                }).then(resp=>{
                                    resp.json().then(res=>{
                                        if(res.resultCode===0){
                                            if(res.expiryDate!==''){
                                                setSimStatus(res.expiryDate);
                                                if(date==='2000/1/1'){
                                                    form.setFieldsValue({iccid_expire:moment(res.openDate, 'YYYY-MM-DD').add(1,'years').endOf('month')});
                                                }
                                            }else{
                                                if(res.active==='未激活'){
                                                    setSimStatus('未激活');
                                                    form.setFieldsValue({iccid_expire:moment(new Date(),'YYYY-MM-DD').add(1,"years").endOf('month') })
                                                }
                                            }
                                            // res.iccid!==''&& form.setFieldsValue({iccid:res.iccid})
                                            if(res.iccid!==''){
                                                if(iccid&&iccid.length==20){
                                                    iccid!==res.iccid&&setSimStatus('iccid不相同，核实卡信息')
                                                }else{
                                                    form.setFieldsValue({iccid:res.iccid})
                                                }
                                            }
                                        }else{
                                            setSimStatus('查询不到，请核实卡号');
                                        }
                                    })
                                }).catch(error=>{
                                    setSimStatus('连接不上查询接口');
                                })
                            });
                        })
                    // }
                    
                }}>读取</Button>}
            </div>
            <div style={{ display:  Mode==='soc'?'flex':'none' }}>
                <div style={{width:260}}>
                    <Form.Item  label="SIMid"
                        labelCol={{ span: 6 }}
                        name="iccid"
                    >
                        <Input controls={false}   style={{width:185}} allowClear/>
                    </Form.Item>
                </div>
                <Button onClick={()=>{
                    // let iccid = form.getFieldValue('iccid');
                    let date = new Date(form.getFieldValue('iccid_expire')).toLocaleDateString();
                    http.get("/v1/device", {}).then(data => {
                        if (data.code === 0) {
                            let tempEles = data.data.filter(x=>x.id===actDevice.id);
                            if(tempEles.length>0 && tempEles[0].iccid.length===20 ){
                                let r = getSimByIccid(tempEles[0].iccid,simToken);
                                setTimeout(()=>{
                                    if(r.iccid_expire&&r.iccid_expire!==''){
                                        if (date==='2000/1/1'){
                                            let now = new Date(r.iccid_expire).getTime()-new Date().getTime();
                                            if(now>365*24*60*60*1000*2){
                                                form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(2,'years')});
                                            }
                                            else if(now>365*24*60*60*1000){
                                                form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(1,'years')});
                                            }else{
                                                form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                                            } 
                                        }else{
                                        }
                                            
                                    }
                                    r.sim&&r.sim!==''&&form.setFieldsValue({sim: r.sim })
                                },1000)
                                form.setFieldsValue({iccid: tempEles[0].iccid })
                            }
                        }
                    })

                }}>读取</Button>
                {
                    Mode === "soc"&&state.main.type<102&&
                    <div style={{width:230}}>
                        <Form.Item  label="卡到期时间"
                            labelCol={{ span: 10}}
                            name="iccid_expire"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130}} disabled={state.main.type >100}/>
                        </Form.Item>
                    </div>
                    
                }
            </div>
            <div style={{ display: 'none' ,marginLeft:10}}>
                <Form.Item label="温度上限"
                    labelCol={{ span: 14}}
                    style={{width:150}} 
                    name="temp_alarm_high"
                    rules={[{ required: true, message: '输入温度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="温度下限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="temp_alarm_low"
                    rules={[{ required: true, message: '输入温度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="湿度上限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="humi_alarm_high"
                    rules={[{ required: true, message: '输入湿度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="湿度下限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="humi_alarm_low"
                    rules={[{ required: true, message: '输入湿度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="测点隐藏"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="hide"
                    valuePropName="checked"
                >
                    <Checkbox style={{ width: 80 }}>隐藏</Checkbox>
                </Form.Item>
                {
                    state.main.type < 103 &&
                    <Form.Item label="安装时间"
                        labelCol={{ span: 8 }}
                        style={{ width: 300 }}
                        name="time"
                        rules={[{ required: true, message: '选择安装时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type > 101}/>
                    </Form.Item>
                }
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="湿度数据"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="humi"
                    valuePropName="checked"
                >
                    <Checkbox>开启</Checkbox>
                </Form.Item>
                {
                    state.main.type < 103  &&
                    <Form.Item label="到期时间"
                        labelCol={{ span: 8 }}
                        style={{ width: 300 }}
                        name="expire"
                        rules={[{ required: true, message: '选择到期时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type > 100}/>
                    </Form.Item>
                }
            </div>
        </Form>
    )
}

function DeviceManage() {
    const { actArea} = useContext(AreaContext)
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actDevice, dispatchDevice] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.device
        } else if (action.type === 'clear') {
            let now = new Date();
            now.setFullYear(now.getFullYear() + 1);
            return {
                name: '测点',//测点名称
                type: 201,//主机类型
                sn: '',
                sim: '',
                m_name: '',
                node: 1,
                district: '',
                humi: 0,
                hide: 0,
                door_alarm: 0,
                power_alarm: 0,
                location: 2,
                status: 0,
                expire: now,
                time: new Date(),
                iccid_expire:new Date('2000-01-01'),
                iccid:''
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const [tableData, setTableData] = useState([])
    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();//对话框移动
    const nodeSerch = React.createRef();
    const devSerch = React.createRef();
    const simSerch = React.createRef();
    useEffect(() => {
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                setTableData(data.data.filter(x=>x.district===actArea.id).sort((a,b)=>a.sn-b.sn));
                dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) });
            }
        })
        http.get("/v1/devicetype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'devicetype', devicetype: data.data });
            }
        })
    }, [])

    // 对话框移动方法
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const columns = [
        {
            title: '测点名称',
            dataIndex: 'name',
            // key: 'id',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            'ID:' + ((record.node * 1 < 10) ? '0' + record.node : record.node)
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={nodeSerch}
                        placeholder='设备信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: vis=>vis&&setTimeout(() => nodeSerch.current.focus({cursor: 'all'}), 100),
            onFilter: (value, record) =>
                record['node']
                    ? (record['node'].toString().toLowerCase().includes(value.toLowerCase()) ||
                        record['name'].toString().toLowerCase().includes(value.toLowerCase())
                    )
                    : '',
        },
        {
            title: '主机名称',
            dataIndex: 'm_name',
            // key: 'id',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.m_name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            record.sn
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={devSerch}
                        placeholder='主机名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: vis=>vis&&setTimeout(() => devSerch.current.focus({cursor: 'all'}), 100),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['m_name']||record['sn']
                    ? record['m_name'].toString().toLowerCase().includes(value.toLowerCase())||record['sn'].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
        },
        {
            title: '设备类型',
            dataIndex: 'type',
            align: 'center',
            sorter: {
                compare:(a, b) => a.type - b.type,
                multiple: 5,
            },
            render: (text, record) => (
                <div>
                    {
                        state.devicetype.filter(x => { return x.code === record.type }).length > 0 ? state.devicetype.filter(x => { return x.code === record.type })[0].value : '无'
                    }
                </div>
            )
        },
        {
            title: 'SIM卡号',
            dataIndex: 'sim',
            // key: 'id',
            align: 'center',
            sorter: {
                compare: (a, b) => a.sim - b.sim,
                multiple: 1,
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={simSerch}
                        placeholder='sim卡号'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => simSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['sim']
                ? record['sim'].includes(value)
                : '',
        },
        {
            title: '添加时间',
            dataIndex: 'time',
            // key: 'id',
            align: 'center',
            sorter:{
                compare: (a, b) => Date.parse(a.time.replace(/T|Z/g, ' ').trim()) - Date.parse(b.time.replace(/T|Z/g, ' ').trim()),
                multiple: 2,
            },
            render:(text, record)=>text= text.slice(0,10),
        },
        {
            title: '设备到期时间',
            dataIndex: 'expire',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.expire.replace(/T|Z/g, ' ').trim()),
                multiple: 3,
            },
            render:(text, record)=>text= text.slice(0,10),
            className:(Mode==='local'||state.main.type>102)&&'notshow'
        },
        {
            title: '卡到期时间',
            dataIndex: 'iccid_expire',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.iccid_expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.iccid_expire.replace(/T|Z/g, ' ').trim()),
                multiple: 3,
            },
            render:(text, record)=>text= text.slice(0,10),
            className:(Mode==='local'||state.main.type>102)&&'notshow'
        },
        {
            title: '状态',
            dataIndex: 'expirestatus',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.expire.replace(/T|Z/g, ' ').trim()),
                multiple: 4,
            },
            render: (text, record) => (
                <div>
                    {
                        parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 0 ? '已到期' : parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 30 ? '即将到期' : '正常'
                    }
                </div>
            ),
            filters: [
                {
                    text: '正常',
                    value: 2
                },
                {
                    text: '即将到期',
                    value: 1
                },
                {
                    text: '已到期',
                    value: 0
                },
            ],
            onFilter: (value, record) => {
                if (value === 2) return parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) > 30;
                if (value === 1) return parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) > 0 && parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) <= 30;
                if (value === 0) return parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 0;
            }
        },

        {
            title: '操作',
            width: 150,
            dataIndex: 'operation',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    {/* 修改按钮 */}
                    <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                        setShowFixModel(true)
                        changeDev = true;
                        dispatchDevice({ type: 'update', device: record })
                    }}>
                        修改
                    </Button>
                    {/* 删除按钮 */}
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除设备${record.name}吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `编号:${record.sn}测点:${record.node}删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/device/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/device", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) })
                                            setTableData(data.data.filter(x=>x.district===actArea.id).sort((a,b)=>a.sn-b.sn))
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    const onfinish = (values) => {
        if (changeDev) {
            http.put("/v1/device/" + actDevice.id, {
                ...values,
                parent:Mode==='soc'?actArea.user_name:'',
                district:actArea.id,
                status: actDevice.status, node: Number(values.node),
                sn: Number(values.sn),
                humi: values.humi ? 1 : 0,
                door_alarm: values.door_alarm ? 1 : 0,
                hide: values.hide ? 1 : 0,
                power_alarm: values.power_alarm ? 1 : 0,
                expire: values.expire?values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                time:values.time ? values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                iccid_expire:values.iccid_expire ? values.iccid_expire.format("YYYY-MM-DD 00:00:00"):actDevice.iccid_expire,
                imei:actDevice.iccid_expire,
            }).then((data) => {
                http.get("/v1/device", {}).then(data => {
                    if (data.code === 0) {
                        dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) })
                        setTableData(data.data.filter(x=>x.district===actArea.id).sort((a,b)=>a.sn-b.sn))
                    }
                })
            })
        } else {
            http.post("/v1/device", {
                ...values,
                parent:Mode==='soc'?actArea.user_name:'',
                district:actArea.id,
                node: Number(values.node),
                sn: Number(values.sn),
                humi: values.humi ? 1 : 0,
                door_alarm: values.door_alarm ? 1 : 0,
                power_alarm: values.power_alarm ? 1 : 0,
                hide: values.hide ? 1 : 0,
                status: values.status ? 1 : 0,
                expire: values.expire?values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                time:values.time ? values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                iccid_expire:values.iccid_expire ? values.iccid_expire.format("YYYY-MM-DD 00:00:00"):actDevice.iccid_expire+' 00:00:00',
                // expire: values.expire.format("YYYY-MM-DD 00:00:00"),
                // time: values.time.format("YYYY-MM-DD 00:00:00")
            }).then((data) => {
                http.get("/v1/device", {}).then(data => {
                    if (data.code === 0) {
                        dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) });
                        setTableData(data.data.filter(x=>x.district===actArea.id).sort((a,b)=>a.sn-b.sn))
                    }
                })
            })
        }

    }
    return (
        <div style={{ padding: 10 }}>
           <div style={{display:'flex'}}>
                {Mode==='soc'&&<div style={{paddingLeft:20}}><span>用户名称: </span>{actArea.user_name}</div>}
                <div style={{paddingLeft:20}}><span>区域名称: </span>{actArea.name}</div>
           </div>
            <div style={{ float: 'right', marginBottom: 10 }}>
                {
                    state.main.type<103&&
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                        onClick={() => {
                            setShowNewModel(true)
                            dispatchDevice({ type: 'clear', project_id: state.main.project_id })
                        }}>新建设备
                    </Button>
                }
            </div>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={tableData} 
                rowClassName={record=>record.status===1&&'socDevice'}
            />

            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    新增设备
                    </div>
                } visible={showNewModel}
                destroyOnClose style={{cursor:'move'}}
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                cancelText='取消'
                width={600}
                okText='确定'
                onOk={() => {
                    form.submit();
                    
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchDevice({ type: 'clear' })
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceNew />
                </DeviceContext.Provider>
            </Modal>
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    修改设备
                    </div>
                } visible={showFixModel}
                destroyOnClose style={{cursor:'move'}}
                modalRender={modal => (
                    <Draggable
                        disabled={modalMoveDisabled}
                        bounds={modalBounds}
                        onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                cancelText='取消'
                width={600}
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    changeDev = false;
                    dispatchDevice({ type: 'clear' })
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceFix />
                </DeviceContext.Provider>
            </Modal>
        </div>
    )
}


function AreaNew() {
    const { actArea, dispatchArea } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    return (
        <div>
            <Input style={{ marginBottom: 5 }} addonBefore="区域名称" value={actArea.name} autoFocus
                onChange={(e) => { dispatchArea({ type: 'update', area: { ...actArea, name: e.target.value } }) }} 
                onKeyDown={e=>{
                    if(e.key==='Enter'){

                    }
                }}
            />
        </div>
    )
}

function AreaManage(props) {
    const [form] = Form.useForm();
    const { state, dispatch } = useContext(StateContext);
    const [actArea, dispatchArea] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.area
        } else if (action.type === 'clear') {
            return {
                name: '',
            }
        }
        return state
    }, []);
    const [actDevice, dispatchDevice] = useReducer((state, action) => {
        if (action.type === 'clear') {
            let now = new Date();
            now.setFullYear(now.getFullYear() + 1);
            return {
                name: '测点',//测点名称
                type: 201,//主机类型
                sn: '',
                sim: '',
                m_name: '',
                node: 1,
                humi: 0,
                hide: 0,
                door_alarm: 0,
                power_alarm: 0,
                location: 2,
                status: 0,
                expire: now,
                time: new Date(),
                user:Mode==='soc'?action.user:'',
                district:action.district
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false);
    const [showFixModel, setShowFixModel] = useState(false);
    const [showNewDevModel, setShowNewDevModel] = useState(false);//添加设备对话框
    const [devMangerModel, setDevMangerModel] = useState(false);//设备管理对话框

    useEffect(() => {
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
            }
        })
        Mode==='soc'&&http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
    }, [])

    const columns = [
        {
            title: '区域名称',
            dataIndex: 'name',
            // key: 'id',
            align: 'left',
            with: '300',
        },
        {
            title: '设备数量',
            align:'center',
            width:'100',
            render:(text,record)=>(
                <div style={{cursor:'pointer'}} onClick={()=>{
                    setDevMangerModel(true);
                    dispatchArea({type: 'update', area: record})
                    }} >
                    <Badge overflowCount={99999} showZero count={state.device.filter(x=>x.district===record.id&&x.node!==0).length} style={{backgroundColor:'palegreen',color:'#333'}}/>
                </div>
            )
        },
        {
            title: '操作',
            dataIndex: 'operation',
            // key: 'id',
            align: 'right',
            width: '260px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixModel(true)
                        dispatchArea({ type: 'update', area: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除区域${record.name}吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `区域${record.name}删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/district/" + record.id, { data: record }).then((data) => {
                                    if (data.code === 0) {
                                        http.get("/v1/district", {}).then(data => {
                                            if (data.code === 0) {
                                                dispatch({ type: 'district', district: data.data })
                                            }
                                        })
                                    }
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                    {
                        state.main.type <103&&Mode==='soc'&&
                        <Button size='small' type='primary'
                        style={{ backgroundColor: 'green', color: '#fff',  marginLeft: '10px' }}
                            onClick={() => {
                                dispatchDevice({ type: 'clear', district: record.id,user:record.user});
                                setShowNewDevModel(true);                 
                            }}>新建设备
                        </Button>
                    }
                </div>
            ),
        },
    ]
    const onfinish = (values) => {
        http.post("/v1/device", {
            ...values,
            parent:actDevice.user,
            district:actDevice.district,
            node: Number(values.node),
            sn: Number(values.sn),
            humi: values.humi ? 1 : 0,
            door_alarm: values.door_alarm ? 1 : 0,
            power_alarm: values.power_alarm ? 1 : 0,
            hide: values.hide ? 1 : 0,
            status: values.status ? 1 : 0,
            expire: values.expire.format("YYYY-MM-DD 00:00:00"),
            time: values.time.format("YYYY-MM-DD 00:00:00")
        })

    }
    return (
        <div  style={{ overflow:'auto',height:'100%',padding: 10 }}>
            <div style={{ display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center', marginBottom: 10,paddingLeft:10 }}>
                <div>{Mode==='soc'&&props.userInfo.name}</div>
                <Button onClick={() => {
                    setShowNewModel(true)
                    dispatchArea({ type: 'clear', project_id: state.main.project_id })
                }}>新建区域</Button>
            </div>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={state.district.filter(x => { return ((props.userInfo && x.user === props.userInfo.id) || (!props.userInfo))&&x.id > 0 })} />
            <Modal maskClosable={false} title="新增区域" visible={showNewModel} 
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    if(actArea.name==='') return
                    http.post("/v1/district", { ...actArea, user: Mode==='soc'?props.userInfo.id : 1 }).then((data) => {
                        if (data.code === 0) {
                            http.get("/v1/district", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'district', district: data.data })
                                }
                            })

                            setShowNewModel(false)
                            dispatchArea({ type: 'clear', project_id: state.main.project_id })
                        } else {
                            Modal.error({
                                title: data.code,
                                content: data.msg,
                            });
                        }
                    })
                    
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchArea({ type: 'clear', project_id: state.main.project_id })
                }}>
                <AreaContext.Provider value={{ actArea, dispatchArea }}>
                    <AreaNew />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改区域" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {

                    http.put("/v1/district/" + actArea.id, { ...actArea, user:Mode==='soc'? props.userInfo.id :1 }).then((data) => {
                        if (data.code === 0) {
                            http.get("/v1/district", {}).then(data => {
                                if (data.code === 0) {
                                    dispatch({ type: 'district', district: data.data })
                                }
                            })
                        }
                    })

                    setShowFixModel(false)
                    dispatchArea({ type: 'clear', project_id: state.main.project_id })
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchArea({ type: 'clear', project_id: state.main.project_id })
                }}>
                <AreaContext.Provider value={{ actArea, dispatchArea }}>
                    <AreaNew />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="新增设备" visible={showNewDevModel}
                destroyOnClose
                cancelText='取消'
                width={600}
                okText='确定'
                onOk={() => {
                    form.submit();
                    setShowNewDevModel(false)
                }}
                onCancel={() => {
                    setShowNewDevModel(false)
                    dispatchDevice({ type: 'clear'})
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceNew />
                </DeviceContext.Provider>
            </Modal>
            <Modal maskClosable={false} title={
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:30,paddingRight:30,paddingTop:10}}>
                    <div><span style={{fontSize:30}}>设 备 管 理</span></div>
                    {/* <div><span>用户名称: </span>{actArea.user_name}</div> */}
                    {/* <div><span>区域名称: </span>{actArea.name}</div> */}
                </div>} 
                style={{ top: 20 }}
                visible={devMangerModel}
                destroyOnClose
                footer={null}
                width={2000}
                onCancel={() => {
                    setDevMangerModel(false)
                }}>
                <AreaContext.Provider value={{actArea,dispatchArea }}>
                    <DeviceManage />
                </AreaContext.Provider>
            </Modal>
        </div>
    )
}

export default AreaManage